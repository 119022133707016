import { MoreAboutYourDamageRoute } from "@/views/building/MoreAboutYourDamage";
import { MoreAboutYourDamageState } from "@/views/building/MoreAboutYourDamage/types";
import { useGetSessionState } from "raci-react-library";

export const useGetMoreAboutYourDamageSummary = () => {
  const { damageDescription } = useGetSessionState<MoreAboutYourDamageState>(MoreAboutYourDamageRoute.path);

  return {
    damageDescription: {
      label: "Damage description",
      content: damageDescription,
    },
  };
};
