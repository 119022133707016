import { CallToActionButton } from "@/shared/components/CallToActionButton";
import PageTitle from "@/shared/components/PageTitle";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Typography } from "@mui/material";
import { FreeTextInput, NotificationCard } from "raci-react-library";
import { FormProvider, useWatch } from "react-hook-form";
import { MoreAboutYourDamageOptionsQuestions } from "./constants";
import { StyledUl } from "./styled";
import { MoreAboutYourDamageFormProps } from "./types";

const DamageDescriptionList = () => (
  <StyledUl style={{ paddingTop: 0 }}>
    <li>How it happened</li>
    <li>What's been damaged</li>
    <li>Which rooms or areas got damaged</li>
  </StyledUl>
);

export const MoreAboutYourDamageForm = ({ form, onSubmit }: MoreAboutYourDamageFormProps) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors }, // Access formState and errors
  } = form;

  const { damageDescription } = MoreAboutYourDamageOptionsQuestions;

  const damageDescriptionValue = useWatch({ control, name: damageDescription.name });

  const isError = errors[damageDescription.name];

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageTitle id="header" title="Please tell us more about your building damage" showClaimNumber />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <p>Describe what happened, including:</p>
              <DamageDescriptionList />
            </Typography>
          </Grid>
          <Grid item xs={12} pt={1} pb={2}>
            <NotificationCard
              icon={<FontAwesomeIcon icon={faInfoCircle} />}
              message={{
                title: "For example",
                content: (
                  <ul style={{ margin: 0, paddingLeft: "20px" }}>
                    <li>
                      The roof leaked during the recent storm, and now the lounge ceiling is stained near the light
                      fitting.
                    </li>
                    <li>Gutters overflowed during heavy rain, and we can see damage to the roof.</li>
                  </ul>
                ),
              }}
              severity="info"
            />
          </Grid>
          <Grid item xs={12}>
            <FreeTextInput
              id={damageDescription.id}
              name={damageDescription.name}
              label={damageDescription.label}
              placeholder="Describe the situation and the damage"
              rows={5}
              maxLength={1000}
              errorMessage="Please describe the damage"
            />
            <Box
              sx={{
                textAlign: "right",
                marginTop: isError ? "-26px" : "0",
              }}
            >
              {damageDescriptionValue ? `${damageDescriptionValue.length}/1000` : "0/1000"}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <CallToActionButton disabled={isSubmitting}>Next</CallToActionButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default MoreAboutYourDamageForm;
