import PreloadReferenceData from "@/shared/components/PreloadReferenceData";
import { TrackPageChanges } from "@/shared/components/TrackPageChanges";
import {
  ADB2C_CLIENT_ID,
  ADB2C_DOMAIN,
  ADB2C_TENANT,
  SESSION_KEY_PREFIX,
  STORAGE_KEY_POLICY_NUMBER,
} from "@/shared/constants";
import useCreateSession from "@/shared/hooks/useCreateSession";
import useGetWebChatInformation from "@/shared/hooks/useGetWebChatInformation";
import useOnExpiry from "@/shared/hooks/useOnExpiry";
import useOnResetExpiry from "@/shared/hooks/useOnResetExpiry";
import { StateMachineContext, usePersistStateMachine } from "@/shared/stateMachine/hooks";
import { reactPlugin } from "@/shared/utils/applicationInsights";
import { getMyRacAuthProps } from "@/shared/utils/auth";
import { BeforeYouStartRoute } from "@/views/common/BeforeYouStart";
import { ConfirmationRoute } from "@/views/common/Confirmation";
import { ContactDetailsRoute } from "@/views/common/ContactDetails";
import { InvoiceQuoteRoute } from "@/views/common/InvoiceQuote";
import { ReviewYourClaimRoute } from "@/views/common/ReviewYourClaim";
import { SafetyAndSecurityRoute } from "@/views/common/SafetyAndSecurity";
import { SessionTimeoutRoute } from "@/views/common/SessionTimeout";
import { SettlementRoute } from "@/views/common/Settlement";
import { StartYourClaimRoute } from "@/views/common/StartYourClaim";
import { SystemUnavailableRoute } from "@/views/common/SystemUnavailable";
import { UploadInvoiceQuoteRoute } from "@/views/common/UploadInvoiceQuote";
import { YouCantMakeAClaimRoute } from "@/views/common/YouCantMakeAClaim";
import { YourBankDetailsRoute } from "@/views/common/YourBankDetails";
import { YourDamagedFenceRoute } from "@/views/common/YourDamagedFence";
import { YourPolicyRoute } from "@/views/common/YourPolicy";
import { YourSettlementOptionsRoute } from "@/views/nonSharedFence/YourSettlementOptions";
import { StormDamageClaimRoute } from "@/views/triage/StormDamageClaim";
import { StormDamagedFenceClaimRoute } from "@/views/triage/StormDamagedFenceClaim";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { RacwaAuth } from "@racwa/react-adb2c";
import { RacwaThemeProvider } from "@racwa/react-components";
import {
  Backdrop,
  CaptureQueryParameter,
  MyRacAuth,
  OpenBackdrop,
  SessionStatePrefixProvider,
  SessionStatusProvider,
  SessionTimeoutDialog,
  SplashScreen,
  WebChat,
  useLogger,
  useSetSplashScreen,
} from "raci-react-library";
import React, { PropsWithChildren, Suspense } from "react";
import {
  Navigate,
  Outlet,
  RouteObject,
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useRouteError,
} from "react-router-dom";
import { RecoilRoot } from "recoil";
import { DocumentServiceSession } from "./shared/components/DocumentServiceSession";
import useSessionExpiry from "./shared/hooks/useSessionExpiry";
import { RouteGuard } from "./shared/routing/RouteGuard";
import { States } from "./shared/stateMachine";
import { stateUrlLookup } from "./shared/stateMachine/lookups";
import { BuildingDamageRoute } from "./views/building/BuildingDamage";
import { FixturesAndFittingsRoute } from "./views/building/FixturesAndFittings";
import { MoreAboutYourDamageRoute } from "./views/building/MoreAboutYourDamage";
import { SafetyChecksRoute } from "./views/building/SafetyChecks";
import { SpecificDamageRoute } from "./views/building/SpecificDamage";
import { WaterDamageRoute } from "./views/building/WaterDamage";
import { YourExcessRoute } from "./views/common/YourExcess";
import { ContentsDamageRoute } from "./views/contents/ContentsDamage";
import { ContentsListRoute } from "./views/contents/ContentsList";

export const Providers: React.FC<PropsWithChildren> = ({ children }) => {
  const setSplashScreen = useSetSplashScreen();

  return (
    <RacwaAuth
      clientId={ADB2C_CLIENT_ID}
      domain={ADB2C_DOMAIN}
      tenant={ADB2C_TENANT}
      onPasswordReset={() => setSplashScreen(true)}
    >
      <RacwaThemeProvider>
        <AppInsightsContext.Provider value={reactPlugin}>
          <Backdrop />
          <SplashScreen />
          <CaptureQueryParameter
            parameter="policyNumber"
            onCapture={(value) => sessionStorage.setItem(STORAGE_KEY_POLICY_NUMBER, value)}
          />
          <SessionStatePrefixProvider prefix={SESSION_KEY_PREFIX}>
            <DocumentServiceSession />
            <MyRacAuth {...getMyRacAuthProps()}>
              <StateMachineContext.Provider>
                <SessionStatusProvider useCreateSession={useCreateSession}>
                  <PreloadReferenceData />
                  {children}
                  <SessionTimeoutDialog
                    useOnResetExpiry={useOnResetExpiry}
                    useOnExpiry={useOnExpiry}
                    disallowPages={[
                      url(StormDamageClaimRoute.path),
                      url(StormDamagedFenceClaimRoute.path),
                      url(BeforeYouStartRoute.path),
                      url(YouCantMakeAClaimRoute.path),
                      url(SessionTimeoutRoute.path),
                      url(SystemUnavailableRoute.path),
                    ]}
                  />
                  <WebChat useGetWebChatInformation={useGetWebChatInformation} />
                  <TrackPageChanges
                    untrackedPages={[
                      "/" + basePath, // process.env.REACT_APP_HOME_PAGE
                      url(StormDamageClaimRoute.path),
                      url(StormDamagedFenceClaimRoute.path),
                      url(BeforeYouStartRoute.path),
                      url(SessionTimeoutRoute.path),
                      url(SystemUnavailableRoute.path),
                    ]}
                  />
                </SessionStatusProvider>
              </StateMachineContext.Provider>
            </MyRacAuth>
          </SessionStatePrefixProvider>
        </AppInsightsContext.Provider>
      </RacwaThemeProvider>
    </RacwaAuth>
  );
};

// NEEDS TO BE THE SAME AS THE PACKAGE.JSON HOMEPAGE
export const basePath = "claims/home/general" as const;

export const url = <T extends Routes>(route: T) => `/${basePath}/${route}` as const;

export const routes = [
  {
    path: "/" as const,
    Component: () => (
      <RecoilRoot>
        <Providers>
          <Suspense fallback={<OpenBackdrop />}>
            <Outlet />
          </Suspense>
        </Providers>
      </RecoilRoot>
    ),
    children: [
      {
        path: basePath,
        Component: () => {
          usePersistStateMachine();
          useSessionExpiry();

          return (
            <>
              <RouteGuard>
                <Outlet />
              </RouteGuard>
            </>
          );
        },
        // GLOBAL ERROR BOUNDARY TO CATCH ANY UNHANDLED EXCEPTIONS
        ErrorBoundary: () => {
          const error = useRouteError();
          const location = useLocation();
          const { logError } = useLogger();

          const currentLocation = location.pathname.toLocaleLowerCase();

          logError({ location: currentLocation, message: error?.toString() });

          const actor = StateMachineContext.useActorRef();
          if (actor.getSnapshot().value !== States.SystemUnavailable) {
            actor.send({ type: "error.systemUnavailable" });
          }
          return <Navigate to={stateUrlLookup[actor.getSnapshot().value]} />;
        },
        children: [
          StormDamageClaimRoute,
          StormDamagedFenceClaimRoute,
          BeforeYouStartRoute,
          ContactDetailsRoute,
          YourPolicyRoute,
          StartYourClaimRoute,
          InvoiceQuoteRoute,
          YourDamagedFenceRoute,
          YourExcessRoute,
          BuildingDamageRoute,
          MoreAboutYourDamageRoute,
          SpecificDamageRoute,
          FixturesAndFittingsRoute,
          WaterDamageRoute,
          SafetyChecksRoute,
          ContentsDamageRoute,
          ContentsListRoute,
          SafetyAndSecurityRoute,
          UploadInvoiceQuoteRoute,
          ReviewYourClaimRoute,
          YourSettlementOptionsRoute,
          YourBankDetailsRoute,
          SettlementRoute,
          ConfirmationRoute,
          YouCantMakeAClaimRoute,
          SessionTimeoutRoute,
          SystemUnavailableRoute,
          // Render a blank page to avoid iframe timeout issues, see:  https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2263
          {
            path: "redirect" as const,
            element: <></>,
          },
          {
            path: "*" as const,
            index: true,
            Component: () => <Navigate to={`/${basePath}/${StormDamageClaimRoute.path}`} />,
          },
        ],
      },
    ],
  },
] as const satisfies Array<RouteObject>;

export type ExtractRoutePaths<T extends RouteObject, P extends string = ""> = P extends string
  ?
      | `/${P}${T["path"]}`
      | (T["children"] extends RouteObject[] ? ExtractRoutePaths<T["children"][number], `${P}${T["path"]}/`> : never)
  : never;

export type Routes = (typeof routes)[number]["children"][number]["children"][number]["path"];
export type Pages = ExtractRoutePaths<(typeof routes)[number]["children"][number]>;

export const router = createBrowserRouter(routes);

export const App: React.FC = () => <RouterProvider router={router} />;

export default App;
