import { Pages } from "@/App";
import { CallToActionButton } from "@/shared/components/CallToActionButton";
import PageTitle from "@/shared/components/PageTitle";
import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { stateUrlLookup, urlBackEventLookup } from "@/shared/stateMachine/lookups";
import { BuildingDamageLevelsTitles } from "@/views/building/BuildingDamage/constants";
import { Grid, Typography } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import {
  InformationSummaryCard,
  InformationSummarySection,
  NotificationCard,
  formatIdString,
} from "raci-react-library";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ExpandableText } from "./components/ExpandableText";
import { PolicyCard } from "./components/PolicyCard";
import { SubSectionWithLabel } from "./components/SubSectionWithLabel";
import { AnswerDetails, ReviewYourClaimFormProps } from "./types";

const yourClaimSectionTitle = "Your claim";
const contactDetailsTitle = "Contact details";
const whenItHappenedTitle = "When it happened";
const fenceRepairsTitle = "Fence repairs";
const contentsDamageTitle = "Your contents";
const contentsListTitle = "Damaged contents";
const buildingDamageTitle = "Building damage";
const moreAboutYourBuildingDamageTitle = "More about your building damage";
const specificDamageTitle = "Specific damage";
const fixturesAndFittingsTitle = "Fixtures and fittings";
const waterDamageTitle = "Water damage inside the home";
const safetyChecksTitle = "Safety checks";
const yourDamagedFenceTitle = "Your damaged fence";
const yourPropertysSafety = "Your property's security";

export const ReviewYourClaimForm = ({
  form,
  policyDetails,
  summaryDetails,
  isYourDamagedFenceCompleted,
  isSafetyAndSecurityCompleted,
  isUploadInvoiceQuoteCompleted,
  isContentsDamagedCompleted,
  isInvoiceQuoteCompleted,
  isContentsListCompleted,
  isBuildingDamageCompleted,
  isSpecificDamageCompleted,
  isMoreAboutYourBuildingDamageCompleted,
  isFixturesAndFittingsCompleted,
  isBuildingWaterDamageCompleted,
  isSafetyChecksCompleted,
  contentsList,
  onSubmit,
}: ReviewYourClaimFormProps) => {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;
  const navigate = useNavigate();
  const {
    yourClaim,
    contactDetails,
    invoiceQuote,
    safetyAndSecurity,
    startYourClaim,
    yourFence,
    uploadInvoiceQuote,
    contentsDamage,
    contentsList: contentsListSummary,
    buildingDamage,
    moreAboutYourDamage,
    specificDamage,
    fixturesAndFittings,
    buildingWaterDamage,
    safetyChecks,
  } = summaryDetails;

  const actor = StateMachineContext.useActorRef();

  const handleOnEditClicked = (title: string, route: Pages) => {
    gtm(event(`Edit - ${title}`));
    const backEvent = urlBackEventLookup(route as keyof typeof urlBackEventLookup);
    if (backEvent) {
      actor.send({ type: backEvent.type });
      navigate(stateUrlLookup[actor.getSnapshot().value]);
    }
  };

  const createSubSection = (sections: (AnswerDetails | undefined)[]) => {
    return (
      <Grid container direction={"column"} spacing={1}>
        {sections.map(
          (section, index) =>
            section && (
              <Grid item key={index}>
                <SubSectionWithLabel label={section.label} content={section.content ?? "-"} />
              </Grid>
            ),
        )}
      </Grid>
    );
  };

  const visibleSections: InformationSummarySection[] = [
    {
      id: `${formatIdString(yourClaimSectionTitle)}-section`,
      title: yourClaimSectionTitle,
      subsections: [
        {
          id: `${formatIdString(yourClaimSectionTitle)}-subsection`,
          content: [createSubSection([yourClaim.damageType, yourClaim.excess])],
        },
      ],
    },
    {
      id: `${formatIdString(contactDetailsTitle)}-section`,
      title: contactDetailsTitle,
      subsections: [
        {
          id: `${formatIdString(contactDetailsTitle)}-subsection`,
          content: [createSubSection([contactDetails.contactNumber, contactDetails.email])],
          onEditClicked: () => handleOnEditClicked(contactDetailsTitle, "/claims/home/general/contact-details"),
        },
      ],
    },
    {
      id: `${formatIdString(whenItHappenedTitle)}-section`,
      title: whenItHappenedTitle,
      subsections: [
        {
          id: `${formatIdString(whenItHappenedTitle)}-subsection`,
          content: [createSubSection([startYourClaim.date, startYourClaim.time])],
        },
      ],
    },
  ];

  const invisibleSection: InformationSummarySection[] = [];

  const contentsDamageSection: InformationSummarySection = {
    id: `${formatIdString(contentsDamageTitle)}-section`,
    title: contentsDamageTitle,
    subsections: [
      {
        id: `${formatIdString(contentsDamageTitle)}-subsection`,
        content: [
          createSubSection([
            contentsDamage.waterDamageToCarpets,
            contentsDamage.cantDryCarpets.content ? contentsDamage.cantDryCarpets : undefined,
          ]),
        ],
        onEditClicked: () => handleOnEditClicked(contentsDamageTitle, "/claims/home/general/contents-damage"),
      },
    ],
  };

  const contentsListSection: InformationSummarySection = {
    id: `${formatIdString(contentsListTitle)}-section`,
    title: contentsListTitle,
    subsections: [
      {
        id: `${formatIdString(contentsListTitle)}-subsection`,
        content: [
          createSubSection([contentsListSummary.anyOtherDamagedContents]),
          contentsListSummary.anyOtherDamagedContents.content === "Yes" && contentsList ? (
            <>
              <Typography variant="body2" id={`list-damaged-items-label`}>
                <b>List each item that has been damaged</b>
              </Typography>
              {contentsList?.map((item) => (
                <SubSectionWithLabel key={item.name} label={item.name} content={`$${item.value}`} />
              ))}
            </>
          ) : (
            <></>
          ),
        ],
        onEditClicked: () => handleOnEditClicked(contentsListTitle, "/claims/home/general/contents-list"),
      },
    ],
  };

  const buildingDamageSection: InformationSummarySection = {
    id: `${formatIdString(buildingDamageTitle)}-section`,
    title: buildingDamageTitle,
    subsections: [
      {
        id: `${formatIdString(buildingDamageTitle)}-subsection`,
        content: [
          <>
            <Typography variant="body2" id={`building-damage`}>
              <b>Please select all the levels that describe your building damage</b>
            </Typography>
            {buildingDamage?.map((damage, index) => (
              <Typography key={damage} variant="body2" id={`$building-damage-level-${index}`}>
                {BuildingDamageLevelsTitles[`${damage}`]}
              </Typography>
            ))}
          </>,
        ],
        onEditClicked: () => handleOnEditClicked(buildingDamageTitle, "/claims/home/general/building-damage"),
      },
    ],
  };

  const moreAboutYourBuildingDamageSection: InformationSummarySection = {
    id: `${formatIdString(moreAboutYourBuildingDamageTitle)}-section`,
    title: moreAboutYourBuildingDamageTitle,
    subsections: [
      {
        id: `${formatIdString(moreAboutYourBuildingDamageTitle)}-subsection`,
        content: [
          <>
            <Typography variant="body2" id={`more-about-your-building-damage`}>
              <b>Describe what happened.</b>
            </Typography>

            <ExpandableText text={moreAboutYourDamage?.damageDescription?.content || "No description available"} />
          </>,
        ],
        onEditClicked: () =>
          handleOnEditClicked(moreAboutYourBuildingDamageTitle, "/claims/home/general/more-about-your-damage"),
      },
    ],
  };

  const specificDamageSection: InformationSummarySection = {
    id: `${formatIdString(specificDamageTitle)}-section`,
    title: specificDamageTitle,
    subsections: [
      {
        id: `${formatIdString(specificDamageTitle)}-subsection`,
        content: [
          <>
            <Typography variant="body2" id={`specific-damage`}>
              <b>Select all the items that have been damaged.</b>
            </Typography>
            {specificDamage?.map((damage, index) => (
              <Typography variant="body2" id={`specific-damage-item-${index}`}>
                {damage}
              </Typography>
            ))}
          </>,
        ],
        onEditClicked: () => handleOnEditClicked(specificDamageTitle, "/claims/home/general/specific-damage"),
      },
    ],
  };

  const buildingWaterDamageSection: InformationSummarySection = {
    id: `${formatIdString(waterDamageTitle)}-section`,
    title: waterDamageTitle,
    subsections: [
      {
        id: `${formatIdString(waterDamageTitle)}-subsection`,
        content: [
          <>
            <Typography variant="body2" id={`water-damage`}>
              <b>Select all the problems your home has</b>
            </Typography>
            {buildingWaterDamage?.map((problem, index) => (
              <Typography variant="body2" id={`water-damage-issue-${index}`}>
                {problem}
              </Typography>
            ))}
          </>,
        ],
        onEditClicked: () => handleOnEditClicked(waterDamageTitle, "/claims/home/general/water-damage"),
      },
    ],
  };

  const fixtureAndFittingsSection: InformationSummarySection = {
    id: `${formatIdString(fixturesAndFittingsTitle)}-section`,
    title: "Damage to fixtures and fittings",
    subsections: [
      {
        id: `${formatIdString(fixturesAndFittingsTitle)}-subsection`,
        content: [createSubSection([fixturesAndFittings.damageToFixturesOrFittings])],
        onEditClicked: () =>
          handleOnEditClicked(fixturesAndFittingsTitle, "/claims/home/general/fixtures-and-fittings"),
      },
    ],
  };

  const safetyChecksSection: InformationSummarySection = {
    id: `${formatIdString(safetyChecksTitle)}-section`,
    title: safetyChecksTitle,
    subsections: [
      {
        id: `${formatIdString(safetyChecksTitle)}-subsection`,
        content: [
          <>
            <Typography variant="body2" id={`safety-checks`}>
              <b>Select all the problems your home has</b>
            </Typography>
            {safetyChecks?.map((problem, index) => (
              <Typography variant="body2" id={`safety-checks-issue-${index}`}>
                {problem}
              </Typography>
            ))}
          </>,
        ],
        onEditClicked: () => handleOnEditClicked(safetyChecksTitle, "/claims/home/general/safety-checks"),
      },
    ],
  };

  const invoiceQuoteSection: InformationSummarySection = {
    id: `${formatIdString(fenceRepairsTitle)}-section`,
    title: fenceRepairsTitle,
    subsections: [
      {
        id: `${formatIdString(fenceRepairsTitle)}-subsection`,
        content: [
          createSubSection([
            invoiceQuote.isRepairCompleted,
            invoiceQuote.hasRepairQuote.content ? invoiceQuote.hasRepairQuote : undefined,
          ]),
        ],
        onEditClicked: () => handleOnEditClicked(fenceRepairsTitle, "/claims/home/general/invoice-quote"),
      },
    ],
  };

  const yourDamagedFenceSection: InformationSummarySection = {
    id: `${formatIdString(yourDamagedFenceTitle)}-section`,
    title: yourDamagedFenceTitle,
    subsections: [
      {
        id: `${formatIdString(yourDamagedFenceTitle)}-subsection`,
        content: [
          createSubSection([
            yourFence.fenceType,
            yourFence.fenceLength.content ? yourFence.fenceLength : undefined,
            yourFence.fencePaintedLength.content ? yourFence.fencePaintedLength : undefined,
            yourFence.fenceDamagedSides,
          ]),
        ],
        onEditClicked: () => handleOnEditClicked(yourDamagedFenceTitle, "/claims/home/general/your-damaged-fence"),
      },
    ],
  };

  const safetyAndSecuritySection: InformationSummarySection = {
    id: `${formatIdString(yourPropertysSafety)}-section`,
    title: yourPropertysSafety,
    subsections: [
      {
        id: `${formatIdString(yourPropertysSafety)}-subsection`,
        content: [createSubSection([safetyAndSecurity.isSafetyIssue])],
        onEditClicked: () => handleOnEditClicked(yourPropertysSafety, "/claims/home/general/safety-and-security"),
      },
    ],
  };

  const uploadDocumentsTitle = "Upload documents";
  const uploadYourInvoiceQuoteTitle = uploadInvoiceQuote.filesUploaded.label;
  const uploadInvoiceQuoteSection: InformationSummarySection = {
    id: `${formatIdString(uploadYourInvoiceQuoteTitle)}-section`,
    title: uploadDocumentsTitle,
    subsections: [
      {
        id: `${formatIdString(uploadDocumentsTitle)}-subsection`,
        content: [createSubSection([uploadInvoiceQuote.filesUploaded])],
        onEditClicked: () =>
          handleOnEditClicked(uploadDocumentsTitle, "/claims/home/general/upload-your-invoice-quote"),
      },
    ],
  };

  const getInvisibleSection = (): InformationSummarySection[] => {
    isBuildingDamageCompleted && invisibleSection.push(buildingDamageSection);
    isMoreAboutYourBuildingDamageCompleted && invisibleSection.push(moreAboutYourBuildingDamageSection);
    isSpecificDamageCompleted && invisibleSection.push(specificDamageSection);
    isFixturesAndFittingsCompleted && invisibleSection.push(fixtureAndFittingsSection);
    isBuildingWaterDamageCompleted && invisibleSection.push(buildingWaterDamageSection);
    isSafetyChecksCompleted && invisibleSection.push(safetyChecksSection);
    isContentsDamagedCompleted && invisibleSection.push(contentsDamageSection);
    isContentsListCompleted && invisibleSection.push(contentsListSection);
    isInvoiceQuoteCompleted && invisibleSection.push(invoiceQuoteSection);
    isYourDamagedFenceCompleted && invisibleSection.push(yourDamagedFenceSection);
    isSafetyAndSecurityCompleted && invisibleSection.push(safetyAndSecuritySection);
    isUploadInvoiceQuoteCompleted && invisibleSection.push(uploadInvoiceQuoteSection);
    return invisibleSection;
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageTitle title="Review and submit your claim" id="header" showClaimNumber />
          </Grid>
          <Grid item xs={12}>
            <InformationSummaryCard
              id="claim-summary-card"
              title="Your claim summary"
              subtitle={<PolicyCard policyDetails={policyDetails} />}
              visibleSections={visibleSections}
              hiddenSections={getInvisibleSection()}
              expandText="More claim details"
              collapseText="Hide claim details"
            />
            <Grid item xs={12} sx={{ paddingTop: "24px" }}>
              <NotificationCard
                severity="info"
                message={{ title: "After this, you won't be able to make any changes online" }}
              />
            </Grid>
            <Grid item xs={12}>
              <CallToActionButton sx={{ margin: "40px 0 32px 0" }} disabled={isSubmitting}>
                Submit claim
              </CallToActionButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default ReviewYourClaimForm;
