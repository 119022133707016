import { FormQuestions } from "@/shared/types";
import { MoreAboutYourDamageFormValues } from "./types";

export const damageDescriptionName = "damageDescription";

export const MoreAboutYourDamageOptionsQuestions = {
  damageDescription: {
    id: " damage-description",
    name: damageDescriptionName,
    label: "",
  },
} as const satisfies FormQuestions<MoreAboutYourDamageFormValues>;
