import useErrorHandling from "@/shared/hooks/useErrorHandling";

import { useSessionState, useSetBackdrop } from "raci-react-library";
import { MoreAboutYourDamageFormProps, MoreAboutYourDamageFormValues, MoreAboutYourDamageState } from "../../types";

import { useBffApiClient } from "@/shared/hooks/useApiClient";
import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { stateUrlLookup } from "@/shared/stateMachine/lookups";
import { ClaimsHomeGeneralDamageApiException } from "raci-claims-home-general-damage-clientproxy";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export const useMoreAboutYourDamage = (): MoreAboutYourDamageFormProps => {
  const actor = StateMachineContext.useActorRef();
  const navigate = useNavigate();
  const apiClient = useBffApiClient();
  const setBackdrop = useSetBackdrop();
  const handleError = useErrorHandling();
  const [moreAboutYourDamageState, setMoreAboutYourDamageState] = useSessionState<MoreAboutYourDamageState>({
    skipPageTrackingRecalculation: true,
  });

  const form = useForm<MoreAboutYourDamageFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: moreAboutYourDamageState,
  });

  const onSubmit: MoreAboutYourDamageFormProps["onSubmit"] = async (values?: MoreAboutYourDamageFormValues) => {
    try {
      setBackdrop(true);

      if (values?.damageDescription !== undefined) {
        setMoreAboutYourDamageState({
          damageDescription: values?.damageDescription,
          isCompleted: true,
        });
      }
      actor.send({
        type: "moreAboutYourDamage.next",
      });
      navigate(stateUrlLookup[actor.getSnapshot().value]);
    } catch (exception) {
      //Todo: update API url
      const error = exception as ClaimsHomeGeneralDamageApiException;
      handleError({
        message: "Error submitting more about the damage",
        shouldRedirect: true,
        customProperties: {
          request: "POST /step/more-about-your-damage",
          status: error.status,
          error: error.message,
        },
      });
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    onSubmit,
  };
};

export default useMoreAboutYourDamage;
