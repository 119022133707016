import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useState } from "react";

const WORD_LIMIT = 50;

export const ExpandableText = ({ text }: { text: string }) => {
  const [expanded, setExpanded] = useState(false);
  const words = text.split(/\s+/).filter(Boolean);
  const shortText = words.length > WORD_LIMIT ? words.slice(0, WORD_LIMIT).join(" ") + "..." : text;

  return (
    <Typography variant="body2">
      {expanded ? text : shortText}{" "}
      {words.length > WORD_LIMIT && (
        <Button
          size="small"
          onClick={() => setExpanded(!expanded)}
          sx={{
            textTransform: "none",
            padding: 0,
            minWidth: "auto",
            color: "#0062B2",
            border: "none",
            background: "none",
            height: "auto",
            boxShadow: "none",
            textDecoration: "none",
            "&:hover": { background: "none", textDecoration: "underline" },
            "&:focus": { outline: "none" },
          }}
        >
          {expanded ? "Show less" : "More"}
        </Button>
      )}
    </Typography>
  );
};
