import useStepperPage from "@/shared/hooks/useStepperPage";
import { createPageTitle } from "@/shared/routing/TitleGuard";
import { RacwaStepperTemplate } from "@racwa/react-components";
import { RouteObject } from "react-router-dom";
import { MoreAboutYourDamageForm } from "./Form";
import { useMoreAboutYourDamage } from "./hooks/useMoreAboutYourDamage";

export const pageName = "More about your damage";

export const MoreAboutYourDamage = () => {
  const props = useMoreAboutYourDamage();
  const stepperProps = useStepperPage();

  return (
    <RacwaStepperTemplate {...stepperProps}>
      <MoreAboutYourDamageForm {...props} />
    </RacwaStepperTemplate>
  );
};

export const MoreAboutYourDamageRoute = {
  path: "more-about-your-damage",
  element: <MoreAboutYourDamage />,
  loader: () => (document.title = createPageTitle(pageName)),
} as const satisfies RouteObject;

export default MoreAboutYourDamage;
